import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Header from "../layouts/Header";
import { getDistance } from "geolib";
import Cookies from 'js-cookie';
import InterviewListEntry from "../components/InterviewListEntry";

const InterviewList = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const partners = useSelector((state) => state.partnerReducer);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [sortedPartners, setSortedPartners] = useState([]);
    const [visibleCount, setVisibleCount] = useState(5);

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${currentCity.picture}) fixed center bottom/cover`,
    };

    const collectDataLocation = (pos) => {
        const crd = pos.coords;
        const data = {
            latitude: crd.latitude,
            longitude: crd.longitude,
        };
        Cookies.set("currentLocation", JSON.stringify(data), { expires: 1/96 });
        setCurrentLocation(data);
    };

    const checkCookieForLocation = () => {
        const savedLocation = Cookies.get("currentLocation");
        if (savedLocation) {
            const { latitude, longitude } = JSON.parse(savedLocation);
            setCurrentLocation({ latitude, longitude });
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (!checkCookieForLocation()) {
            navigator.geolocation.getCurrentPosition(collectDataLocation, () => setCurrentLocation(null));
        }
    }, []);

    useEffect(() => {
        if (partners.length > 0) {
            const filteredPartners = partners.filter(partner => partner.city === currentCity._id);
            let partnersWithDistance = filteredPartners.filter((partner) => partner.interviewName && partner.isActive);

            if (currentLocation) {
                const partnersWithCoordinates = partnersWithDistance.filter(partner => partner.coordinates && partner.coordinates[0] != null && partner.coordinates[1] != null);
                const partnersWithoutCoordinates = partnersWithDistance.filter(partner => !partner.coordinates || partner.coordinates[0] == null || partner.coordinates[1] == null);

                const sortedPartnersWithCoordinates = partnersWithCoordinates.map((partner) => {
                    const distance = getDistance(
                        { latitude: currentLocation.latitude, longitude: currentLocation.longitude },
                        { latitude: partner.coordinates[0], longitude: partner.coordinates[1] }
                    ) / 1000;
                    return { ...partner, distance };
                }).sort((a, b) => a.distance - b.distance);

                partnersWithDistance = [...sortedPartnersWithCoordinates, ...partnersWithoutCoordinates];
            }

            setSortedPartners(partnersWithDistance);
        }
    }, [partners, currentLocation, currentCity]);

    const loadMore = () => {
        setVisibleCount(prevCount => prevCount + 5);
    };

    return (
        <div className="interview-list-container" style={cityBackground}>
            <Header />
            <h1>À la rencontre de ...</h1>
            <section className="interview-list">
                {sortedPartners.slice(0, visibleCount).map((partner) => (
                    <InterviewListEntry key={partner._id} interview={partner} />
                ))}
            </section>
            {visibleCount < sortedPartners.length && (
                <button onClick={loadMore} className="load-more-button">Afficher plus</button>
            )}
        </div>
    );
};

export default InterviewList;