import React, {useState} from 'react';
import {useSelector} from "react-redux";
import Header from "../layouts/Header";
import {useLocation} from "react-router";
import {isEmpty} from "../assets/Utils";
import logoWeb from "../assets/images/logo-web.png";
import {NavLink} from "react-router-dom";

const Interview = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const location = useLocation();
    const currentPartner = location.state;
    const [expandedText, setExpandedText] = useState({});

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    const truncateText = (text, maxLength, isExpanded) => {
        if (isExpanded || text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const handleTextClick = (index) => {
        setExpandedText((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const getSubDomainByName = (name) => {
        return name
            .trimStart()
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replaceAll(" ", "-")
            .replaceAll("'", "")
            .replaceAll("/", "-")
            .replaceAll("---", "-");
    }

    return (
        <div className="interview-container" style={cityBackground}>
            <Header navPath={"interviewList"} stringBtn={"Retour aux rencontres"}/>
            <h1>À la rencontre de ...</h1>
            <section className="interview">
                <div className="interview-information">
                    <div className="image-container">
                        <img
                            src={
                                "https://api.lesbonsplans.pro" +
                                "/upload/partner/image/" +
                                currentPartner.interviewPhoto
                            }
                            alt={"Vignette partenaire " + currentPartner.interviewName}
                        />
                    </div>
                    <div className="text-container">
                        <div className="text-information-container">
                            <div className="text-name-container">
                                <h2>{currentPartner.interviewFirstName}</h2>
                                <h2>{currentPartner.interviewName}</h2>
                            </div>
                            <div className="text-job-container">
                                <h3>{currentPartner.interviewJob}</h3>
                            </div>
                        </div>
                        <div className="text-description-container">
                            {currentPartner.interviewText.map((partnerText, index) => (
                                <div
                                    className="text-description"
                                    key={index}
                                    onClick={() => handleTextClick(index)}
                                    style={{cursor: 'pointer'}}
                                >
                                    <p style={{whiteSpace: 'pre-wrap'}}>{truncateText(partnerText, 300, expandedText[index])}</p>
                                    {!expandedText[index] && (
                                        <div className="read-more">
                                            Lire la suite
                                            <i className="arrow"></i>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="partner-information">
                    <div className="partner-information-title">
                        <img
                            src={
                                "https://api.lesbonsplans.pro" +
                                "/upload/partner/image/" +
                                currentPartner.logo
                            }
                            alt={"Vignette partenaire " + currentPartner.name}
                        />
                        <NavLink
                            to={"/partenaires/" + currentCity.subDomain + "/" + getSubDomainByName(currentPartner.category[0]) + "/" + currentPartner.subDomain}>
                            <button className="btn btn-interview">Plus d'informations ici !</button>
                        </NavLink>
                    </div>
                    <div className="partner-information-content">
                        {!isEmpty(currentPartner.coordinates) ? (
                            <a
                                href={`https://www.google.com/maps?q=${currentPartner.coordinates[0]},${currentPartner.coordinates[1]}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {currentPartner.streetNumber}
                                {currentPartner.streetName && <><br/>{currentPartner.streetName}</>}
                            </a>
                        ) : (
                            <a
                                href={`https://www.google.com/maps?q=${currentPartner.streetNumber} ${currentPartner.streetName ? currentPartner.streetName : ""} ${currentCity.postalCode} ${currentCity.name}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {`${currentPartner.streetNumber}, ${currentPartner.streetName ? currentPartner.streetName : ""}`}
                                <br/>
                                {` ${currentCity.postalCode} ${currentCity.name}`}
                            </a>
                        )}
                    </div>
                </div>
                {(currentPartner.website || currentPartner.reservation || currentPartner.meeting || currentPartner.shopping || currentPartner.phoneNumber || currentPartner.emailAddress || !isEmpty(currentPartner.socialMedia)) && (
                    <div className="contact-currentPartner-container">
                        <div className="info-contact-currentPartner-container">
                            {currentPartner.website && (
                                <a href={currentPartner.website} target="_blank" rel="noreferrer">
                                    <img src={logoWeb} alt="logo-web"/>
                                </a>
                            )}
                            {currentPartner.reservation && (
                                <a href={currentPartner.reservation} target="_blank" rel="noreferrer">
                                    <i className="bx bxs-calendar"></i>
                                </a>
                            )}
                            {currentPartner.meeting && (
                                <a href={currentPartner.meeting} target="_blank" rel="noreferrer">
                                    <i className="bx bxs-calendar"></i>
                                </a>
                            )}
                            {currentPartner.shopping && (
                                <a href={currentPartner.shopping} target="_blank" rel="noreferrer">
                                    <i className="bx bxs-cart"></i>
                                </a>
                            )}
                            {currentPartner.phoneNumber && (
                                <a href={`tel:${currentPartner.phoneNumber}`}>
                                    <i className="bx bxs-phone"></i>
                                </a>
                            )}
                            {currentPartner.emailAddress && (
                                <a href={`mailto:${currentPartner.emailAddress}`}>
                                    <i className="bx bxs-envelope"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.google && (
                                <a href={currentPartner.socialMedia.google} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-google"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.facebook && (
                                <a href={currentPartner.socialMedia.facebook} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-facebook-circle"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.instagram && (
                                <a href={currentPartner.socialMedia.instagram} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-instagram-alt"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.twitter && (
                                <a href={currentPartner.socialMedia.twitter} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-twitter"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.pinterest && (
                                <a href={currentPartner.socialMedia.pinterest} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-pinterest"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.tiktok && (
                                <a href={currentPartner.socialMedia.tiktok} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-tiktok"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.youtube && (
                                <a href={currentPartner.socialMedia.youtube} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-youtube"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.linkedin && (
                                <a href={currentPartner.socialMedia.linkedin} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-linkedin-square"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.tripadvisor && (
                                <a href={currentPartner.socialMedia.tripadvisor} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-trip-advisor"></i>
                                </a>
                            )}
                            {currentPartner.socialMedia && currentPartner.socialMedia.googleAvis && (
                                <a href={currentPartner.socialMedia.googleAvis} target="_blank" rel="noreferrer">
                                    <i className="bx bxl-google-plus"></i>
                                </a>
                            )}
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
};

export default Interview;