import React from "react";
import { isEmpty } from "../assets/Utils";

const PopupPartnerInfos = ({ partner, closePopup }) => {
  return (
      <div className="popup popup-info-partner">
        <div className="popup-info-partner-container">
          <div className="popup-info-partner-header">
            <img
                src={
                    "https://api.lesbonsplans.pro" +
                    "/upload/partner/image/" +
                    partner.logo
                }
                alt={"Logo " + partner.name}
            />
            <i onClick={() => closePopup({})} className="bx bx-x-circle"></i>
          </div>
          <div className="popup-info-partner-content">
            <h3>{partner.name}</h3>
            {partner.phoneNumber && <p>Tel : {partner.phoneNumber}</p>}
            {partner.emailAddress && <p>Email : {partner.emailAddress}</p>}
            {partner.website && (
                <p>
                  Site web :{" "}
                  <a href={partner.website} target="_blank" rel="noreferrer">
                    {partner.website}
                  </a>
                </p>
            )}
            {!isEmpty(partner.socialMedia) && (
                <div className="social-media-partner">
                  {partner.socialMedia.google && (
                      <a
                          href={partner.socialMedia.google}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <i className="bx bxl-google"></i>
                      </a>
                  )}
                  {partner.socialMedia.facebook && (
                      <a
                          href={partner.socialMedia.facebook}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <i className="bx bxl-facebook-circle"></i>
                      </a>
                  )}
                  {partner.socialMedia.instagram && (
                      <a
                          href={partner.socialMedia.instagram}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <i className="bx bxl-instagram-alt"></i>
                      </a>
                  )}
                  {partner.socialMedia.twitter && (
                      <a
                          href={partner.socialMedia.twitter}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <i className="bx bxl-twitter"></i>
                      </a>
                  )}
                  {partner.socialMedia.pinterest && (
                      <a
                          href={partner.socialMedia.pinterest}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <i className="bx bxl-pinterest"></i>
                      </a>
                  )}
                  {partner.socialMedia.tiktok && (
                      <a
                          href={partner.socialMedia.tiktok}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <i className="bx bxl-tiktok"></i>
                      </a>
                  )}
                  {partner.socialMedia.youtube && (
                      <a
                          href={partner.socialMedia.youtube}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <i className="bx bxl-youtube"></i>
                      </a>
                  )}
                  {partner.socialMedia.linkedin && (
                      <a
                          href={partner.socialMedia.linkedin}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <i className="bx bxl-linkedin-square"></i>
                      </a>
                  )}
                  {partner.socialMedia.tripadvisor && (
                      <a
                          href={partner.socialMedia.tripadvisor}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <i className="bx bxl-trip-advisor"></i>
                      </a>
                  )}
                    {partner.socialMedia.googleAvis && (
                        <a
                            href={partner.socialMedia.googleAvis}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="bx bxl-google-plus"></i>
                        </a>
                    )}
                </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default PopupPartnerInfos;
