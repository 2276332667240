import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { setCategory } from "../actions/category.action";
import { generatesubDomain } from "../assets/Utils";

const createOption = (label, subDomain) => ({
  label,
  value: label,
  subDomain: subDomain,
});

const CategorySelector = ({ onChange, value, placeholder = "Sélectionnez ou créez une catégorie", isSingle = false, mainCategory }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const dataCategory = useSelector((state) => state.categoryReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    let tempOptions = [];
    dataCategory.forEach((category) => {
      tempOptions.push(createOption(category.name, category.subDomain));
    });
    tempOptions.sort((a, b) => a.label.localeCompare(b.label));
    setOptions(tempOptions);
  }, [dataCategory]);

  useEffect(() => {
    if (mainCategory) {
      setOptions((prevOptions) => prevOptions.filter(option => option.value !== mainCategory.value));
    } else {
      let tempOptions = [];
      dataCategory.forEach((category) => {
        tempOptions.push(createOption(category.name, category.subDomain));
      });
      tempOptions.sort((a, b) => a.label.localeCompare(b.label));
      setOptions(tempOptions);
    }
  }, [mainCategory, dataCategory]);

  const handleCreate = async (inputValue) => {
    setIsLoading(true);
    const newCategory = { name: inputValue, subDomain: generatesubDomain(inputValue, dataCategory), isActive: true };
    await dispatch(setCategory(newCategory));
    const newOption = createOption(inputValue, newCategory.subDomain);
    setOptions((prev) => [...prev, newOption]);
    onChange(isSingle ? newOption : [...(value || []), newOption]);
    setIsLoading(false);
  };

  const handleMoveToTop = (option) => {
    const newValue = [option, ...(value || []).filter(v => v.value !== option.value)];
    onChange(newValue);
  };

  const handleDelete = (option) => {
    const newValue = (value || []).filter(v => v.value !== option.value);
    onChange(newValue);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      margin: "20px 0",
      padding: "5px",
      minWidth: "50%",
      backgroundColor: "#e2b259",
      border: "2px solid #fff",
      boxShadow: "none",
      transition: "0.2s ease",
      "&:hover": {
        border: "2px solid #ccc",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
      color: state.isFocused ? "white" : "black",
      ":active": {
        backgroundColor: "white",
        color: "black",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValue: (provided, state) => {
      const isFirst = state.index === 0;
      const backgroundColor = mainCategory ? "#b1852d" : (isFirst ? "red" : "#b1852d");
      return {
        ...provided,
        backgroundColor,
      };
    },
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
      cursor: "pointer", // Add cursor pointer to indicate it's clickable
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      background: "#b1852d",
      color: "white",
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      transition: "0.2s ease",
      "&:hover": {
        color: "#ccc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#e2b259",
      border: "2px solid white",
      borderRadius: "4px",
      marginTop: "4px",
      zIndex: 9999,
    }),
  };

  return (
      <CreatableSelect
          isMulti={!isSingle}
          isClearable
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={onChange}
          onCreateOption={handleCreate}
          options={options}
          value={value}
          styles={customStyles}
          placeholder={placeholder}
          components={{
            MultiValue: ({ children, ...props }) => (
                <div {...props.innerProps} style={props.getStyles("multiValue", props)}>
                  <span onClick={() => handleMoveToTop(props.data)}>{children}</span>
                  <button onClick={() => handleDelete(props.data)}>X</button>
                </div>
            ),
          }}
      />
  );
};

export default CategorySelector;