import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../layouts/Header";

const IframeLink = () => {
    const location = useLocation();
    const iframeLink = location.state?.Link ;
    const title = location.state?.title;

    return (
        <div className="iframe-link-container">
            <Header stringBtn={"Retourner découvrir"} navPath={"decouverte"} />
            <h1>{title}</h1>
            <div className="button-container">
                <button className="btn btn-link" onClick={() => window.open(iframeLink, '_blank')}>
                    Si la page ne s'affiche pas ou qu'elle s'affiche mal, cliquez ici.
                </button>
            </div>
            <section className="iframe-container">
                <iframe title="actualites" src={iframeLink} frameBorder="0"></iframe>
            </section>
        </div>
    );
};

export default IframeLink;