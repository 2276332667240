import axios from "axios";
import { deletePartner } from "./partner.action";
import {deletePictureGuidedTour} from "./pictureGuidedTour.action";
import {deleteContentCity} from "./contentCity.action";

export const GET_CITY = "GET_CITY";
export const SET_CITY = "SET_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const CURRENT_CITY = "CURRENT_CITY";
export const DELETE_CITY = "DELETE_CITY";
export const CALCULATE_DISTANCE = "CALCULATE_DISTANCE";


export const getCity = () => {
    return async (dispatch) => {
        return axios
            .get("https://api.lesbonsplans.pro" + "/city")
            .then((res) => {
                dispatch({ type: GET_CITY, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const setCity = (city) => {
    return async (dispatch) => {
        return axios
            .post("https://api.lesbonsplans.pro" + "/city", city)
            .then((res) => {
                dispatch({ type: SET_CITY, payload: city });
            })
            .catch((err) => console.log(err));
    };
};

export const updateCity = (city) => {
    return async (dispatch) => {
        return axios
            .put("https://api.lesbonsplans.pro" + "/city/" + city._id, city)
            .then((res) => {
                dispatch({ type: UPDATE_CITY, payload: { ...res.data, ...city } });
            })
            .catch((err) => console.log(err));
    };
};

export const setCurrentCity = (city) => {
    return async (dispatch) => {
        dispatch({ type: CURRENT_CITY, payload: city });
    };
};

export const deleteCity = (city, partners, contentCity) => {
    return async (dispatch) => {
        try {
            dispatch( await deletePictureGuidedTour(city.picture));
            dispatch( await deletePictureGuidedTour(city.logo));

            await Promise.all(partners.map((partner) => dispatch(deletePartner(partner))));

            dispatch( await deleteContentCity(contentCity));
            await axios.delete("https://api.lesbonsplans.pro" + "/city/" + city._id);
            dispatch({
                type: DELETE_CITY,
                payload: city._id,
            });
        } catch (err) {
            console.log(err);
        }
    };
};

export const calculateDistance = (cityCoordinates, userCoordinates) => {
    return async (dispatch) => {
        try {
            const response = await axios.post("https://api.lesbonsplans.pro/city/calculateDistance", {
                cityCoordinates,
                userCoordinates
            });
            return response.data.distance;
        } catch (error) {
            console.error("Erreur lors du calcul de la distance", error);
            throw error;
        }
    };
};