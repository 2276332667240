import React from "react";
import { NavLink } from "react-router-dom";

const InterviewListEntry = ({ interview }) => {
    return (
        <div className="interview-entry">
            <NavLink to={`/interview`} state={interview}>
                <img
                    src={`https://api.lesbonsplans.pro/upload/partner/image/${interview.interviewPhoto}`}
                    alt={`Vignette partenaire ${interview.interviewName}`}
                />
                <div className="text-container">
                    <h2>{interview.interviewFirstName} {interview.interviewName}</h2>
                    <p>{interview.interviewJob}</p>
                    <p>{interview.distance ? interview.distance.toFixed(2) : 'N/A'} km</p>
                </div>
            </NavLink>
        </div>
    );
};

export default InterviewListEntry;