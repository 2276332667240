import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {useParams} from "react-router";

const PartnerListEntry = ({ partner, distance }) => {
  const currentCity = useSelector((state) => state.cityReducer);
  const { categorysubDomain } = useParams();

  return (
      <div className="partner-entry">
        <NavLink
            to={`/partenaires/${currentCity.subDomain}/${categorysubDomain}/${partner.subDomain}`}
            state={partner}
        >
          <img
              src={
                  "https://api.lesbonsplans.pro" +
                  "/upload/partner/image/" +
                  partner.logo
              }
              alt={"Vignette partenaire " + partner.name}
          />
            <div className="text-container">
                <h2>{partner.name}</h2>

                <p>
                    {partner.streetNumber}
                    {partner.streetName && <><br/>{partner.streetName}</>}
                </p>
                <p>{distance ? `${distance} km` : "N/A"}</p>

            </div>
        </NavLink>
      </div>
  );
};

export default PartnerListEntry;