import {useSelector} from "react-redux";
import Header from "../layouts/Header";
import React from "react";

const GuidePrestige = () => {
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const currentCity = useSelector((state) => state.cityReducer);

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    return (
        <div className="iframe-link-container" style={cityBackground}>
            <Header/>
            <h1>Guide Prestige</h1>
            <div className="button-container">
                <button className="btn btn-link"
                        onClick={() => window.open(contentCurrentCity.guidePrestigeLink, '_blank')}>Si la page ne s'affiche
                    pas ou qu'elle s'affiche mal, cliquez ici.
                </button>
            </div>
            <section className="iframe-container">
                <iframe
                    title="actualites"
                    src={contentCurrentCity.guidePrestigeLink}
                    frameBorder="0"
                ></iframe>
            </section>
        </div>
    );
}
export default GuidePrestige;

