import React, {useEffect, useState} from "react";
import partage_safari from '../assets/images/partage_safari.png';
import partage_chrome from '../assets/images/partage_chrome.png';
import ajouteralecran from '../assets/images/ajouteralecran.jpg';
import ajouter from '../assets/images/ajouter.jpg';
import Cookies from "js-cookie";

const WelcomeModal = ({
                          show,
                          onClose,
                          supportsPWA,
                          onClickChrome,
                          redirectToChrome,
                          onClickNoSafari,
                          onClickSafari,
                          isSafari
                      }) => {
    if (!show) return null;

    return (
        <div className="popup">
            <div className="popup-content popup-welcome">
                <button onClick={onClose} className="close-button">❌</button>
                <h1>Bienvenue !</h1>
                {isSafari ? (
                    <>
                        <p>Veuillez sélectionner un des deux navigateurs ci-dessous et suivre les quelques instructions pour
                            installer l'application :</p>
                        <div className="apple_choose">
                            <button onClick={onClickSafari} className="chrome-btn">Safari</button>
                            <button onClick={onClickNoSafari} className="chrome-btn">Google Chrome</button>
                        </div>
                    </>
                ) : (
                    <>
                        <p>Veuillez sélectionner le navigateur ci-dessous et suivre les quelques instructions pour
                            installer l'application :</p>
                        <button onClick={supportsPWA ? onClickChrome : redirectToChrome} className="chrome-btn">Google
                            Chrome
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};


const Modal = ({show, onClose}) => {
    if (!show) return null;

    return (
        <div className="popup">
            <div className="popup-content">
                <button onClick={onClose} className="close-button">❌</button>
                <iframe
                    src="https://www.google.com/chrome/"
                    title="Télécharger Google Chrome"
                    className="popup-iframe"
                />
            </div>
        </div>
    );
};

const InstructionsModal = ({show, onClose, selectedBrowser}) => {
    useEffect(() => {
        if (show && !Cookies.get('app_installed')) {
            Cookies.set('app_installed', 'true', { expires: 36500 });
        }
    }, [show]);
    if (!show) return null;

    return (
        <div className="popup">
            <div className="popup-content popup-instruction install-text">
                <button onClick={onClose} className="close-button close-button-instruction">❌</button>
                <h1>Pour ajouter cette application à votre écran d'accueil :</h1>
                <p id="majuscule">1. Cliquez sur l'icone de partage</p>
                <p id="minuscume">(icône entourée en rouge ci-dessous) :</p>
                <div className="img-chrome-safari">
                    {selectedBrowser === 'Safari' ? (
                        <>
                            <p>Si vous utilisez Safari :</p>
                            <img src={partage_safari} alt="Step 1"/>
                        </>
                    ) : (
                        <>
                            <p>Si vous utilisez Google Chrome :</p>
                            <img src={partage_chrome} alt="Step 1"/>
                        </>
                    )}
                </div>
                <p>2. Faites défiler vers le bas et sélectionnez "Ajouter à l'écran d'accueil".</p>
                <img src={ajouteralecran} alt="Step 2"/>
                <p>3. Appuyez sur "Ajouter" dans le coin supérieur droit.</p>
                <img src={ajouter} alt="Step 3"/>
            </div>
        </div>
    );
};

const InstallPWA = () => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);
    const [showModalWelcome, setShowModalWelcome] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isSafari, setIsSafari] = useState(false);
    const [selectedBrowser, setSelectedBrowser] = useState(null);
    const [isInstalled, setIsInstalled] = useState(false);

    useEffect(() => {

        const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
        setIsInstalled(isStandalone);
        const isSafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
        setIsSafari(isSafariBrowser);

        const handler = (e) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };

        const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);
        setShowModalWelcome(!Cookies.get('app_installed') && isMobileDevice);

        window.addEventListener("beforeinstallprompt", handler);
        window.addEventListener("appinstalled", () => {
            window.location.reload();
        });

        return () => {
            window.removeEventListener("beforeinstallprompt", handler);
            window.removeEventListener("appinstalled", () => {
            });
        };
    }, []);

    useEffect(() => {
        if (isInstalled) {
            if (!Cookies.get('app_installed')) {
                Cookies.set('app_installed', 'true', { expires: 36500 });
            }
        }
    }, [isInstalled]);


    const handleBrowserClick = (browser) => {
        setSelectedBrowser(browser);
        openInstructionsModal();
    };

    const onClick = (evt) => {
        evt.preventDefault();
        if (promptInstall) {
            promptInstall.prompt();
            promptInstall.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    Cookies.set('app_installed', 'true', { expires: 36500 });
                }
                setPromptInstall(null);
            });
        }
    };

    const redirectToChrome = () => {
        window.location = "intent://www.lesbonsplans.pro#Intent;package=com.android.chrome;scheme=https;end;";
        if (isMobile) {
            window.location = "https://www.google.com/chrome/";
        } else {
            setTimeout(function () {
                openModal();
            }, 2000);
        }
    };

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const openInstructionsModal = () => setShowInstructionsModal(true);
    const closeInstructionsModal = () => setShowInstructionsModal(false);

    const openModalWelcome = () => setShowModalWelcome(true);
    const closeModalWelcome = () => setShowModalWelcome(false);

    return (
        <div>
            {isInstalled ? null : (
                <div className="install-pwa">
                    <button
                        className="install-btn"
                        id="setup_button"
                        aria-label="Installer l'application"
                        title="Installer l'application"
                        onClick={openModalWelcome}
                    ><h1>Cliquez ici pour installer l'application sur l'écran d'accueil de votre appareil</h1>
                    </button>
                    <WelcomeModal
                        show={showModalWelcome}
                        onClose={closeModalWelcome}
                        supportsPWA={supportsPWA}
                        onClickChrome={onClick}
                        redirectToChrome={redirectToChrome}
                        onClickSafari={() => handleBrowserClick('Safari')}
                        onClickNoSafari={() => handleBrowserClick('Chrome')}
                        isSafari={isSafari}
                    />
                    <Modal show={showModal} onClose={closeModal}/>
                    <InstructionsModal show={showInstructionsModal} onClose={closeInstructionsModal}
                                       selectedBrowser={selectedBrowser}/>

                </div>
            )}
        </div>
    );
};

export default InstallPWA;