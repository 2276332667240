import React, { useEffect, useState } from "react";
import { isEmpty } from "../assets/Utils";
import { useSelector } from "react-redux";
import CityListEntry from "../components/CityListEntry";
import BackButton from "../components/BackButton";
import LogoLbp from "../components/LogoLbp";
import CountrySelect from "../components/CountrySelect";
import { getDistance } from "geolib";
import Cookies from 'js-cookie';

const CitiesList = () => {
    const dataCity = useSelector((state) => state.cityReducer);
    const [currentLocation, setCurrentLocation] = useState({});
    const [sortedCities, setSortedCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [visibleCitiesCount, setVisibleCitiesCount] = useState(5);
    const [geoError, setGeoError] = useState(false);

    const collectDataLocation = (pos) => {
        const crd = pos.coords;
        const data = {
            latitude: crd.latitude,
            longitude: crd.longitude,
        };
        Cookies.set("currentLocation", JSON.stringify(data), { expires: 1/96 }); // 15 minutes
        setCurrentLocation(data);
    };

    const checkCookieForLocation = () => {
        const savedLocation = Cookies.get("currentLocation");
        if (savedLocation) {
            const { latitude, longitude } = JSON.parse(savedLocation);
            setCurrentLocation({ latitude, longitude });
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (Array.isArray(dataCity)) {
            setSortedCities(dataCity.sort((a, b) => a.name.localeCompare(b.name)));
        }
        if (!checkCookieForLocation()) {
            navigator.geolocation.getCurrentPosition(collectDataLocation, () => {
                setGeoError(true);
            });
        }
    }, [dataCity]);

    const findClosestCity = (cities, userLat, userLng) => {
        if (!userLat || !userLng) return null;
        let closest = null;
        let minDistance = Infinity;

        cities
            .filter((city) => city.isActive) // Consider only active cities
            .forEach((city) => {
                const [cityLat, cityLng] = city.coordinates;
                const distance = getDistance(
                    { latitude: userLat, longitude: userLng },
                    { latitude: cityLat, longitude: cityLng }
                ) / 1000;
                if (distance < minDistance) {
                    minDistance = distance;
                    closest = city;
                }
            });

        return closest;
    };

    const sortCities = (dataCity) => {
        if (isEmpty(currentLocation) || geoError) {
            return dataCity.sort((a, b) => a.name.localeCompare(b.name));
        }

        const citiesWithDistances = dataCity.map((city) => {
            const distance = getDistance(
                { latitude: currentLocation.latitude, longitude: currentLocation.longitude },
                { latitude: city.coordinates[0], longitude: city.coordinates[1] }
            ) / 1000;
            return { ...city, distance };
        });

        return citiesWithDistances.sort((a, b) => a.distance - b.distance);
    };

    useEffect(() => {
        if (Array.isArray(dataCity) && !isEmpty(dataCity) && (currentLocation.latitude && currentLocation.longitude || geoError)) {
            const closestCity = findClosestCity(dataCity, currentLocation.latitude, currentLocation.longitude);
            if (closestCity && !geoError) {
                setSelectedCountry({ value: closestCity.country, label: closestCity.country });
                const filteredCities = dataCity.filter((city) => city.country === closestCity.country);
                setSortedCities(sortCities(filteredCities));
            } else {
                setSortedCities(sortCities(dataCity));
            }
        }
    }, [dataCity, currentLocation, geoError]);

    const handleFormChange = (e) => {
        e.preventDefault();
        const searchValue = e.target.value.toLowerCase();
        setSearchInput(searchValue);
        if (Array.isArray(dataCity)) {
            const filteredCities = dataCity.filter((city) => {
                const matchesName = city.name.toLowerCase().includes(searchValue);
                const matchesCountry = selectedCountry ? city.country === selectedCountry.value : true;
                return matchesName && matchesCountry;
            });
            setSortedCities(sortCities(filteredCities));
        }
    };

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        const searchValue = searchInput.toLowerCase();
        if (Array.isArray(dataCity)) {
            const filteredCities = dataCity.filter((city) => {
                const matchesName = city.name.toLowerCase().includes(searchValue);
                const matchesCountry = selectedOption ? city.country === selectedOption.value : true;
                return matchesName && matchesCountry && city.isActive;
            });
            setSortedCities(sortCities(filteredCities));
        }
    };

    const loadMoreCities = () => {
        setVisibleCitiesCount((prevCount) => prevCount + 10);
    };

    return (
        <div className="cities-list-container">
            <section className="header-container">
                <section className="header-container-btn">
                    <BackButton navPath="ma-ville" stringBtn="Retour à ma ville" />
                </section>
                <LogoLbp />
            </section>
            <section className="city-selector">
                <div className="city-searchbar-container">
                    <h1>Choisissez votre ville</h1>
                    <form>
                        <CountrySelect
                            onChange={handleCountryChange}
                            value={selectedCountry}
                        />
                        <input
                            type="text"
                            placeholder="Indiquez ici le nom de la ville recherchée..."
                            onChange={(e) => handleFormChange(e)}
                        />
                    </form>
                </div>
                <div className="city-display">
                    {!isEmpty(sortedCities) &&
                        sortedCities
                            .filter((city) => city.isActive === true)
                            .slice(0, visibleCitiesCount)
                            .map((city) => (
                                <CityListEntry
                                    key={city._id}
                                    city={city}
                                    currentLocation={currentLocation}
                                />
                            ))}
                    {visibleCitiesCount < sortedCities.length && (
                        <div className="load-more-cities">
                            <button className="load-more-cities-button" onClick={loadMoreCities}>
                                <i className="bx bxs-buildings"></i>
                                <span>Charger plus de villes</span>
                            </button>
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};

export default CitiesList;