import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import { NavLink } from "react-router-dom";
import { getDistance } from "geolib";
import Cookies from 'js-cookie';

const GuidedTourList = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const currentCityContent = useSelector((state) => state.contentCityReducer);
    const dataGuidedTour = useSelector((state) => state.guidedTourReducer);
    const [guidedTourList, setGuidedTourList] = useState([]);
    const [userPosition, setUserPosition] = useState(null);
    const [visibleCount, setVisibleCount] = useState(5);

    const savePositionToCookie = (position) => {
        const data = {
            latitude: position.lat,
            longitude: position.lon,
        };
        Cookies.set("currentLocation", JSON.stringify(data), { expires: 1/96 }); // 15 minutes
    };

    const checkCookieForPosition = () => {
        const savedPosition = Cookies.get("currentLocation");
        if (savedPosition) {
            const { latitude, longitude } = JSON.parse(savedPosition);
            setUserPosition({ lat: latitude, lon: longitude });
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (!checkCookieForPosition()) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const newPosition = {
                            lat: position.coords.latitude,
                            lon: position.coords.longitude,
                        };
                        savePositionToCookie(newPosition);
                        setUserPosition(newPosition);
                    },
                    (error) => {
                        console.error("Erreur lors de la récupération de la position :", error);
                        setUserPosition(null);
                    });
            } else {
                console.error("La géolocalisation n'est pas supportée par ce navigateur.");
                setUserPosition(null);
            }
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(currentCityContent) && !isEmpty(dataGuidedTour)) {
            const guidedTourListTemp = dataGuidedTour.filter((tour) =>
                currentCityContent.guidedTour.includes(tour._id)
            );
            setGuidedTourList(guidedTourListTemp);
        }
    }, [currentCityContent, dataGuidedTour]);

    useEffect(() => {
        if (userPosition && !isEmpty(guidedTourList)) {
            const toursWithDistance = guidedTourList.map((tour) => {
                const distance = getDistance(
                    { latitude: userPosition.lat, longitude: userPosition.lon },
                    { latitude: tour.coordinates[0], longitude: tour.coordinates[1] }
                ) / 1000; // Convert meters to kilometers
                return { ...tour, distance: distance.toFixed(2) };
            });

            if (JSON.stringify(toursWithDistance) !== JSON.stringify(guidedTourList)) {
                setGuidedTourList(toursWithDistance.sort((a, b) => a.distance - b.distance));
            }
        }
    }, [userPosition, guidedTourList]);

    const loadMore = () => {
        setVisibleCount(prevCount => prevCount + 5);
    };

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${currentCity.picture}) fixed center bottom/cover`,
    };

    return (
        <div className="guided-tour-list-container" style={cityBackground}>
            <Header stringBtn={"Retourner découvrir"} navPath={"decouverte"} />
            <h1>Visites Guidées</h1>
            <section className="guided-tour-list">
                {!isEmpty(guidedTourList) &&
                    guidedTourList.slice(0, visibleCount).map((tour) => (
                        <div className="guided-tour-list-entry" key={tour._id}>
                            <NavLink to={"/visite-guidee"} state={tour}>
                                <img
                                    src={
                                        "https://api.lesbonsplans.pro" +
                                        "/upload/upload/image/" +
                                        tour.picture
                                    }
                                    alt={"Image de la visite guidée " + tour.name}
                                    loading="lazy"
                                />
                                <h2>{tour.name}</h2>
                                <div className="distance">
                                    <p>{tour.distance ? `${tour.distance} km` : "N/A"}</p>
                                </div>
                            </NavLink>
                        </div>
                    ))}
            </section>
            {visibleCount < guidedTourList.length && (
                <button onClick={loadMore} className="load-more-button">Afficher plus</button>
            )}
        </div>
    );
};

export default GuidedTourList;