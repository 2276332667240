import React, {useEffect, useState} from "react";
import android_troisPoints from '../assets/images/android_chrome_3point.jpg';
import android_infos from '../assets/images/android_chrome_info.jpg';
import android_position from '../assets/images/android_chrome_position.jpg';
import android_switch from '../assets/images/android_chrome_switch.jpg';
import apple_visuel1 from '../assets/images/APPLE-VISUEL1.png';
import apple_visuel2 from '../assets/images/APPLE-VISUEL2.png';
import apple_visuel3 from '../assets/images/APPLE-VISUEL3.png';
import apple_visuel4 from '../assets/images/APPLE-VISUEL4.png';
import chrome_visuel1 from '../assets/images/CHROME-VISUEL1.png';
import chrome_visuel2 from '../assets/images/CHROME-VISUEL2.png';
import chrome_visuel3 from '../assets/images/CHROME-VISUEL3.png';
import chrome_visuel4 from '../assets/images/CHROME-VISUEL4.png';
import Cookies from "js-cookie";

const WelcomeModal = ({
                          show,
                          onClose,
                          openChromeModal,
                          onClickNoSafari,
                          onClickSafari,
                          isSafari
                      }) => {
    if (!show) return null;

    return (
        <div className="popup">
            <div className="popup-content popup-welcome">
                <button onClick={onClose} className="close-button">❌</button>
                <h1>Activation géolocalisation :</h1>
                {isSafari ? (
                    <>
                        <p>Merci de sélectionner un des deux navigateurs ci-dessous et suivre les quelques instructions
                            pour autoriser la géolocalisation :</p>
                        <div className="apple_choose">
                            <button onClick={onClickSafari} className="chrome-btn">Safari</button>
                            <button onClick={onClickNoSafari} className="chrome-btn">Google Chrome</button>
                        </div>
                    </>
                ) : (
                    <>
                        <p>Merci de sélectionner le navigateur ci-dessous et suivre les quelques instructions pour
                            autoriser la géolocalisation :</p>
                        <button onClick={openChromeModal} className="chrome-btn">Google
                            Chrome
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};


const Modal = ({show, onClose}) => {
    if (!show) return null;

    return (
        <div className="popup">
            <div className="popup-content popup-instruction install-text">
                <button onClick={onClose} className="close-button close-button-instruction">❌</button>
                <h1>Pour autoriser la géolocation :</h1>
                <div className="img-chrome-safari">
                    <p id="majuscule">1. Cliquez sur l'icone avec les trois points</p>
                    <p id="minuscume">(icône entourée en rouge ci-dessous) :</p>
                    <img src={android_troisPoints} alt="Step 1"/>
                    <p>2. Puis cliquez sur rond avec un "i" à l'intérieur.</p>
                    <img src={android_infos} alt="Step 2"/>
                    <p>3. Appuyez sur "Autorisations".</p>
                    <img src={android_position} alt="Step 3"/>
                    <p>4. Enfin, cliquez sur le bouton de position pour l'autoriser.</p>
                    <img src={android_switch} alt="Step 3"/>
                </div>

            </div>
        </div>
    );
};

const InstructionsModal = ({show, onClose, selectedBrowser}) => {
    useEffect(() => {
        if (show && !Cookies.get('app_installed')) {
            Cookies.set('app_installed', 'true', {expires: 36500});
        }
    }, [show]);
    if (!show) return null;

    return (
        <div className="popup">
            <div className="popup-content popup-instruction install-text">
                <button onClick={onClose} className="close-button close-button-instruction">❌</button>
                <h1>Pour autoriser la géolocation :</h1>
                <div className="img-chrome-safari">
                    {selectedBrowser === 'Safari' ? (
                        <>
                            <p id="majuscule">1. Cliquez sur icône entourée en rouge ci-dessous</p>
                            <img src={apple_visuel1} alt="Step 1"/>
                            <p>2. Cliquez sur les trois points.</p>
                            <img src={apple_visuel2} alt="Step 2"/>
                            <p>3. Appuyez sur "Position".</p>
                            <img src={apple_visuel3} alt="Step 3"/>
                            <p>4. Et selectionnez "Autoriser".</p>
                            <img src={apple_visuel4} alt="Step 3"/>
                        </>
                    ) : (
                        <>
                            <p id="majuscule">1. Allez dans les réglages de votre téléphone</p>
                            <img src={chrome_visuel1} alt="Step 1"/>
                            <p>2. Dans la barre de recherche, tapez "Chrome".</p>
                            <img src={chrome_visuel2} alt="Step 2"/>
                            <p>3. Appuyez sur "Position".</p>
                            <img src={chrome_visuel3} alt="Step 3"/>
                            <p>4. Enfin, selectionnez "Lorsque l'app est active".</p>
                            <p id="minuscume">(icône entourée en rouge ci-dessous) :</p>
                            <img src={chrome_visuel4} alt="Step 3"/>
                        </>
                    )}
                </div>

            </div>
        </div>
    );
};

const GeoLocationInstructions = () => {
    const [showModalWelcome, setShowModalWelcome] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [isSafari, setIsSafari] = useState(false);
    const [selectedBrowser, setSelectedBrowser] = useState(null);

    useEffect(() => {

        const isSafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
        setIsSafari(isSafariBrowser);
    }, []);


    const handleBrowserClick = (browser) => {
        setSelectedBrowser(browser);
        openInstructionsModal();
    };

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const openInstructionsModal = () => setShowInstructionsModal(true);
    const closeInstructionsModal = () => setShowInstructionsModal(false);

    const openModalWelcome = () => setShowModalWelcome(true);
    const closeModalWelcome = () => setShowModalWelcome(false);

    return (
        <div>
            <div className="install-pwa">
                <button
                    className="install-btn-geo-info"
                    id="setup_button"
                    aria-label="Installer l'application"
                    title="Installer l'application"
                    onClick={openModalWelcome}
                ><h3 className="geo-info">Cliquez ici afin d'autoriser la géolocation pour une expérience
                    utilisateur encore meilleur !</h3>
                </button>
                <WelcomeModal
                    show={showModalWelcome}
                    onClose={closeModalWelcome}
                    openChromeModal={openModal}
                    onClickSafari={() => handleBrowserClick('Safari')}
                    onClickNoSafari={() => handleBrowserClick('Chrome')}
                    isSafari={isSafari}
                />
                <Modal show={showModal} onClose={closeModal}/>
                <InstructionsModal show={showInstructionsModal} onClose={closeInstructionsModal}
                                   selectedBrowser={selectedBrowser}/>

            </div>
        </div>
    );
};

export default GeoLocationInstructions;