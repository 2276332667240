import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setCurrentCity } from "../actions/city.action";
import { getContentCityById } from "../actions/contentCity.action";
import { getDistance } from "geolib";

const CityListEntry = ({ city, currentLocation }) => {
    const dispatch = useDispatch();
    const [distance, setDistance] = useState(null);

    const onClickRedirect = (currentCity) => {
        dispatch(setCurrentCity(currentCity));
        dispatch(getContentCityById(currentCity.contentCity));
    };

    useEffect(() => {
        const fetchCoordinatesAndCalculateDistance = async () => {
            if (currentLocation && currentLocation.latitude && currentLocation.longitude) {
                let cityCoordinates = await city.coordinates;
                if (city.name) {
                    const dist = getDistance(
                        { latitude: currentLocation.latitude, longitude: currentLocation.longitude },
                        { latitude: cityCoordinates[0], longitude: cityCoordinates[1] }
                    ) / 1000;
                    setDistance(dist.toFixed(2));
                }
            }
        };

        fetchCoordinatesAndCalculateDistance();
    }, [currentLocation, city]);

    return (
        <div className="city-list-entry-container">
            <NavLink
                to="/accueil"
                key={city._id}
                state={city}
                onClick={() => onClickRedirect(city)}
            >
                <img
                    src={
                        "https://api.lesbonsplans.pro" +
                        "/upload/city/image/" +
                        city.picture
                    }
                    alt={"Photo ville " + city.name}
                />
                <div className="nameDistance">
                    <span className="name"> {city.name}</span>
                    <div className="distance">
                        <p>{distance ? `${distance} km` : "N/A"}</p>
                    </div>
                </div>
            </NavLink>
        </div>
    );
};

export default CityListEntry;