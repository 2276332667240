import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCity } from "../actions/city.action";
import { getContentCity } from "../actions/contentCity.action";
import { isEmpty } from "../assets/Utils";
import LogoCity from "../components/LogoCity";

const Home = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const dataMessage = useSelector((state) => state.messageReducer);
    const partners = useSelector((state) => state.partnerReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [hasPartnerCity, setHasPartnerCity] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("contentCityMemo")) {
            localStorage.removeItem("contentCityMemo");
            navigate("/");
            window.location.reload();
        }
    }, [navigate]);

    useEffect(() => {
        const checkPartnerCity = async () => {
            const result = await verifPartnerCity();
            setHasPartnerCity(result);
        };
        checkPartnerCity();
    }, [currentCity, partners]);

    const onClickRedirectCities = async () => {
        dispatch(getCity());
        dispatch(getContentCity());
        window.location.reload();
    };

    const verifPartnerCity = async () => {
        if (isEmpty(currentCity)) {
            await dispatch(getCity());
        }
        return partners.some((partner) => partner.city === currentCity._id && partner.isActive);
    }

    const mostRecentMessage = dataMessage
        .filter((message) => new Date(message.datePublication) <= new Date())
        .sort((a, b) => new Date(b.datePublication) - new Date(a.datePublication))[0];

    const isMostRecentMessageRead = () => {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('readMessageId='))
            ?.split('=')[1];
        return cookieValue === mostRecentMessage?._id;
    };

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    const hasActivePartnerWithInterview = () => {
        return partners.some(partner =>
            partner.city === currentCity._id &&
            partner.isActive &&
            partner.interviewName
        );
    };

    return (
        <div className="home-container nav-page-container" style={cityBackground}>
            <section className="logo-container">
                <LogoCity imageUrl={`https://api.lesbonsplans.pro/upload/city/image/${currentCity.logo}`} />
            </section>
            <section className="nav-container">
                <nav className="nav-categories">
                    <ul>
                        <li>
                            <NavLink to="/" onClick={() => onClickRedirectCities()}>
                                <i className="bx bxs-home"></i>Villes
                            </NavLink>
                        </li>
                        {hasPartnerCity && (
                            <li>
                                <NavLink to="/categories">
                                    <i className="bx bxs-heart"></i>Les Bonnes Adresses
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/decouverte">
                                <i className="bi bi-search-heart"></i>Découverte
                            </NavLink>
                        </li>
                        {!isEmpty(contentCurrentCity.guidePrestigeLink) && (
                            <li>
                                <NavLink to="/GuidePrestige">
                                    <i className="bx bxs-gift"></i>Les Bons Plans
                                </NavLink>
                            </li>
                        )}
                        {!isEmpty(contentCurrentCity.messages) && (
                            <li>
                                <NavLink to="/nos-messages">
                                    <i className="bx bxs-envelope" style={{ color: !isMostRecentMessageRead() ? "red" : "white" }}></i>Nos Messages
                                </NavLink>
                            </li>
                        )}
                        {hasActivePartnerWithInterview() && (
                            <li>
                                <NavLink to="/interviewList">
                                    <i className="bx bxs-microphone"></i>Interviews
                                </NavLink>
                            </li>
                        )}
                        {!isEmpty(contentCurrentCity.calendarLink) && (
                            <li>
                                <NavLink to="/agenda">
                                    <i className="bx bxs-calendar"></i>Agenda
                                </NavLink>
                            </li>
                        )}
                        {!isEmpty(currentCity.socialMedia) && (
                            <li>
                                <NavLink to="/reseaux-sociaux">
                                    <i className="bx bxs-chat"></i>Réseaux sociaux
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/contact">
                                <i className="bx bxs-contact"></i>Contact
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </section>
        </div>
    );
};

export default Home;