import React from 'react';
import HeaderAdmin from "../layouts/HeaderAdmin";
import NavbarAdmin from "../components/NavbarAdmin";



const Statistiques = () => {




    return (
        <div className="statistiques-admin-container">
            <HeaderAdmin/>
            <section className="admin-content-container">
                <NavbarAdmin/>
                <div className="admin-content">
                    <h2>Analytics Data</h2>
                    <div className="button-container">
                        <button className="btn btn-link"
                                onClick={() => window.open("https://analytics.google.com/", '_blank')}>
                            Si le graphique ne s'affiche pas ou qu'il s'affiche mal, cliquez ici.
                        </button>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default Statistiques;