import React, {useEffect} from "react";
import Header from "../layouts/Header";
import { NavLink } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import {getMagazine} from "../actions/magazine.action";

const MagazineRedirect = () => {
  const currentCity = useSelector((state) => state.cityReducer);
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getMagazine());
  }, []);

  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
      currentCity.picture
    }) fixed center bottom/cover`,
  };
  return (
    <div className="magazine-redirect-container" style={cityBackground}>
      <Header navPath={"decouverte"} stringBtn={"Retourner découvrir"} />
      <h1>Les magazines</h1>
      <section className="magazine-redirect">
        <ul>
            {!isEmpty(contentCurrentCity.magazines) && (
                <li>
                  <NavLink to={`/nos-magazines/${currentCity.subDomain}`}>
                    <i className="bx bx-book-open"></i>Nos magazines
                  </NavLink>
                </li>
            )}
          {contentCurrentCity.cityMagLink && (
            <li>
              <NavLink to={"/magazine-de-la-ville"}>
                <i className="bx bx-podcast"></i>Magazine de la ville
              </NavLink>
            </li>
          )}
        </ul>
      </section>
    </div>
  );
};

export default MagazineRedirect;
