import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/Header";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import logoPj from "../assets/images/logo-pj-notxt.jpg";

const PracticalGuideRedirect = () => {
  const currentCity = useSelector((state) => state.cityReducer);
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);
  const navigate = useNavigate();

  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
        currentCity.picture
    }) fixed center bottom/cover`,
  };

  return (
      <div className="practical-guide-redirect-container" style={cityBackground}>
        <Header />
        <h1>Découverte</h1>
        <div className="nav-guides">
          <ul>
            <li>
              <NavLink to="/index-rues">
                <i className="bx bxs-directions"></i>Plan/Index des rues
              </NavLink>
            </li>
            <li>
              <NavLink to="/pages-jaunes">
                <img src={logoPj} alt="Logo Pages Jaunes" width={100} />
                Pages jaunes
              </NavLink>
            </li>
            {!isEmpty(contentCurrentCity.weatherLink) && (
                <li>
                  <button onClick={() => navigate("/iframe-link", {state: {Link: contentCurrentCity.weatherLink, title: "La météo"}})}>
                    <i className="bx bxs-cloud"></i>La météo
                  </button>
                </li>
            )}
            {!isEmpty(contentCurrentCity.infoCityLink) && (
                <li>
                  <button onClick={() => navigate("/iframe-link", {state: {Link: contentCurrentCity.infoCityLink, title: "Infos pratiques"}})}>
                    <i className="bx bxs-info-circle"></i>Infos pratiques
                  </button>
                </li>
            )}
            {!isEmpty(contentCurrentCity.liveCamLink) && (
                <li>
                  <button onClick={() => navigate("/iframe-link", {state: {Link: contentCurrentCity.liveCamLink, title: "La ville en direct"}})}>
                    <i className="bx bxs-video"></i>La ville en direct
                  </button>
                </li>
            )}
            {!isEmpty(contentCurrentCity.cityNewsLink) && (
                <li>
                  <button onClick={() => navigate("/iframe-link", { state: { Link: contentCurrentCity.cityNewsLink, title: "Actualités" } })}>
                    <i className="bx bxs-news"></i>Actualités
                  </button>
                </li>
            )}
            {(!isEmpty(contentCurrentCity.cityMagLink) || !isEmpty(contentCurrentCity.magazines)) && (
                <li>
                  <NavLink to="/magazines">
                    <i className="bx bx-book"></i>Magazines
                  </NavLink>
                </li>
            )}
            {!isEmpty(contentCurrentCity.guidedTour) && (
                <li>
                  <NavLink to="/visites-guidees">
                    <i className="bx bxs-user-voice"></i>Visites guidées
                  </NavLink>
                </li>
            )}
            {!isEmpty(contentCurrentCity.interactCarteLink) && (
                <li>
                  <button onClick={() => navigate("/iframe-link", {state: {Link: contentCurrentCity.interactCarteLink, title: "Carte interactive"}})}>
                    <i className="bx bxs-map"></i>Carte interactive
                  </button>
                </li>
            )}
            {!isEmpty(contentCurrentCity.someNumberLink) && (
                <li>
                  <button onClick={() => navigate("/iframe-link", {state: {Link: contentCurrentCity.someNumberLink, title: "Quelques Chiffres"}})}>
                    <i className="bx bxs-report"></i>Quelques Chiffres
                  </button>
                </li>
            )}
          </ul>
        </div>
      </div>
  );
};

export default PracticalGuideRedirect;