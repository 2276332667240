import React, { useState } from 'react';
import { useSelector } from "react-redux";
import Header from "../layouts/Header";
import { isEmpty } from "../assets/Utils";
import { NavLink } from "react-router-dom";

const MessagesList = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dataMessages = useSelector((state) => state.messageReducer);
    const [visibleCount, setVisibleCount] = useState(5);

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    const currentDate = new Date();

    const filteredAndSortedMessages = dataMessages
        .filter((message) =>
            contentCurrentCity.messages.includes(message._id) &&
            new Date(message.datePublication) <= currentDate
        )
        .sort((a, b) => new Date(b.datePublication) - new Date(a.datePublication));

    const mostRecentMessage = filteredAndSortedMessages[0];

    const isMostRecentMessageRead = () => {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('readMessageId='))
            ?.split('=')[1];
        return cookieValue === mostRecentMessage._id;
    };

    const handleClick = (messageId) => {
        if (messageId === mostRecentMessage._id) {
            const date = new Date();
            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
            document.cookie = `readMessageId=${messageId}; expires=${date.toUTCString()}; path=/;`;
        }
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const loadMore = () => {
        setVisibleCount(prevCount => prevCount + 5);
    };

    return (
        <div className="message-list-container" style={cityBackground}>
            <Header />
            <h1>Nos Messages</h1>
            <section className="message">
                {!isEmpty(contentCurrentCity.messages) &&
                    Array.isArray(dataMessages) &&
                    filteredAndSortedMessages.slice(0, visibleCount).map((message) => (
                        <div
                            key={message._id}
                            className="message-entry"
                        >
                            <NavLink to={"/message"} state={message} onClick={() => handleClick(message._id)}>
                                <h3 style={{ color: !isMostRecentMessageRead() && message._id === mostRecentMessage._id ? 'red' : 'white' }}>
                                    {message.title}
                                </h3>
                                <div className="message-content">
                                    <img
                                        src={
                                            "https://api.lesbonsplans.pro/upload/partner/image/" +
                                            message.logo
                                        }
                                        alt="image visite"
                                        loading="lazy"
                                    />
                                    <p>{truncateText(message.content, 300)}</p>
                                </div>
                                <div className="read-more">
                                    Lire la suite
                                    <i className="arrow"></i>
                                </div>
                                <div className="publication-date">
                                    Publié le {new Date(message.datePublication).toLocaleDateString()}
                                </div>
                            </NavLink>
                        </div>
                    ))}
            </section>
            {visibleCount < filteredAndSortedMessages.length && (
                <button onClick={loadMore} className="load-more-button">Afficher plus</button>
            )}
        </div>
    );
};

export default MessagesList;