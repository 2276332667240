import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import {setPartner} from "../actions/partner.action";
import Select from "react-select";
import CategorySelector from "../components/CategorySelector";
import {
    deleteElement,
    resetReducer,
    setLogoPartner,
    setInterviewPhotoPartner,
    setPicturePartner,
    setVideoPartner,
} from "../actions/partnerUploads.action";
import AdminSortingPartnerUploads from "../components/AdminSortingPartnerUploads";
import {pictureCompressor} from "../assets/utils/pictureCompressor";

const AdminNewPartner = ({popupActive}) => {
    const dataCity = useSelector((state) => state.cityReducer);
    const dataPartner = useSelector((state) => state.partnerReducer);
    const contentNewPartner = useSelector((state) => state.partnerUploadsReducer);
    const [partnerName, setPartnerName] = useState("");
    const [partnerSubDomain, setPartnerSubDomain] = useState("");
    const [coordinatesOn, setCoordinatesOn] = useState(false);
    const [cityNewPartner, setCityNewPartner] = useState({});
    const [mainCategory, setMainCategory] = useState(null);
    const [secondaryCategories, setSecondaryCategories] = useState([]);
    const [socialMediaNewPartner, setSocialMediaNewPartner] = useState({});
    const [optionsCity, setOptionsCity] = useState([{}]);
    const [logoNewPartner, setLogoNewPartner] = useState("");
    const [interviewPhotoNewPartner, setInterviewPhotoNewPartner] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [flashMessage, setFlashMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [interviewText, setInterviewText] = useState('');
    const [additionalTexts, setAdditionalTexts] = useState([]);
    const [activeTextIndex, setActiveTextIndex] = useState(null);
    const dispatch = useDispatch();

    const handleOpenModal = (index) => {
        setActiveTextIndex(index);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setActiveTextIndex(null);
    };

    const handleAddText = () => {
        setAdditionalTexts([...additionalTexts, ""]);
    };

    const handleTextChange = (index, value) => {
        const newTexts = [...additionalTexts];
        newTexts[index] = value;
        setAdditionalTexts(newTexts);
    };

    const handleModalTextChange = (value) => {
        if (activeTextIndex === null) {
            setInterviewText(value);
        } else {
            handleTextChange(activeTextIndex, value);
        }
    };


    const normalizeString = (string) => {
        return string
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
    };

    useEffect(() => {
        if (!isEmpty(partnerName)) {
            const partSubDomContent = normalizeString(partnerName.toLowerCase())
                .replaceAll(" ", "-")
                .replaceAll("'", "");
            let subDomainAvailable = 0;
            dataPartner.forEach((partner) => {
                if (partner.subDomain === partSubDomContent) {
                    subDomainAvailable += 1;
                }
            });
            if (subDomainAvailable > 0) {
                setPartnerSubDomain(partSubDomContent + subDomainAvailable.toString());
            } else {
                setPartnerSubDomain(partSubDomContent);
            }
        } else {
            setPartnerSubDomain("");
        }
    }, [partnerName]);

    useEffect(() => {
        let tempOptionsCity = [];
        dataCity.forEach((city) => {
            let tempLabel = `${city.name} (${city.postalCode})`;
            tempOptionsCity.push({value: city._id, label: tempLabel, subDomain: city.subDomain});
        });
        setOptionsCity(tempOptionsCity);
    }, []);

    useEffect(() => {
        if (!isEmpty(contentNewPartner.logo)) {
            setLogoNewPartner(contentNewPartner.logo);
        }
        if(!isEmpty(contentNewPartner.interviewPhoto)) {
            setInterviewPhotoNewPartner(contentNewPartner.interviewPhoto);
        }
    }, [contentNewPartner.logo, contentNewPartner.interviewPhoto]);

    const handleIsFormValid = () => {
        const form = document.getElementById("form-new-partner");
        if (form.checkValidity() && !isEmpty(cityNewPartner)) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    const handleCitySelected = (value) => {
        setCityNewPartner(value);
        handleIsFormValid();
    };

    const handleCoordinateChecked = (e) => {
        if (e.target.checked) {
            setCoordinatesOn(true);
        } else {
            setCoordinatesOn(false);
        }
        handleIsFormValid();
    };

    const onChangeCategorySelector = (values, isMain = false) => {
        if (isMain) {
            setMainCategory(values);
            setFlashMessage(""); // Clear flash message when main category is selected
        } else {
            if (!mainCategory) {
                setFlashMessage("Veuillez choisir une catégorie principale avant de sélectionner des catégories secondaires.");
                return;
            }
            setSecondaryCategories(values);
        }
        handleIsFormValid();
    };

    const handleSocialMediaChanged = (e) => {
        const input = e.target;
        if (isEmpty(input.value)) {
            const tempSocialMedia = {...socialMediaNewPartner};
            delete tempSocialMedia[input.name];
            setSocialMediaNewPartner(tempSocialMedia);
            return;
        }
        setSocialMediaNewPartner({
            ...socialMediaNewPartner,
            [input.name]: input.value,
        });
    };

    const handleLogoUpload = async (e) => {
        const file = e.target.files[0];
        let formDataLogo = await pictureCompressor(file);
        if (!isEmpty(logoNewPartner)) {
            dispatch(await deleteElement(logoNewPartner));
            dispatch(await setLogoPartner(formDataLogo));
            return;
        }
        dispatch(await setLogoPartner(formDataLogo));
        handleIsFormValid();
    };

    const handleInterviewPhotoUpload = async (e) => {
        const file = e.target.files[0];
        let formDataPhotoInterview = await pictureCompressor(file);
        if (!isEmpty(interviewPhotoNewPartner)) {
            dispatch(await deleteElement(interviewPhotoNewPartner));
            dispatch(await setInterviewPhotoPartner(formDataPhotoInterview));
            return;
        }
        dispatch(await setInterviewPhotoPartner(formDataPhotoInterview));
        handleIsFormValid();
    };

    const handlePictureUpload = async (e) => {
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let formDataPicture = await pictureCompressor(files[i]);
            dispatch(await setPicturePartner(formDataPicture));
        }
        handleIsFormValid();
    };

    const handleVideoUpload = async (e) => {
        const file = e.target.files[0];
        let formDataVideo = new FormData();
        formDataVideo.append("file", file);
        dispatch(await setVideoPartner(formDataVideo));
        handleIsFormValid();
    };

    const validateInterviewFields = () => {
        const interviewFields = [
            { id: "interview-name", name: "Nom" },
            { id: "interview-first-name", name: "Prénom" },
            { id: "interview-job", name: "Profession" },
            { id: "interview-text", name: "Texte" },
            { id: "interview-photo-content", name: "Photo" }
        ];

        const filledFields = interviewFields.filter(field => {
            const element = document.getElementById(field.id);
            return element && element.value.trim() !== "";
        });

        if (filledFields.length > 0 && filledFields.length < interviewFields.length) {
            const missingFields = interviewFields
                .filter(field => !filledFields.includes(field))
                .map(field => field.name);

            const missingFieldsList = missingFields.map(field => `- ${field}`).join('\n');
            alert(`Les champs de l'interview suivants sont manquants :\n${missingFieldsList}`);
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isFormValid || !mainCategory) {
            alert("La catégorie principale n'est pas correcte.");
            return;
        }
        if (!validateInterviewFields()) {
            return;
        }

        const form = e.target;

        let tempCategories = [mainCategory.value];
        secondaryCategories.forEach((category) => {
            tempCategories.push(category.value);
        });
        const urlPartner = "https://www.lesbonsplans.pro/partenaires/" + cityNewPartner.subDomain + "/" +
            mainCategory.subDomain + "/" + partnerSubDomain;
        let uploadsNewPartner = contentNewPartner.uploads;
        let newPartner = {
            name: form.name.value,
            city: cityNewPartner.value,
            streetNumber: form["street-number"].value,
            streetName: form["street-name"].value,
            category: tempCategories,
            logo: contentNewPartner.logo,
            phoneNumber: form.tel.value,
            emailAddress: form.email.value,
            website: form.website.value,
            reservation: form.reservation.value,
            meeting: form.meeting.value,
            shopping: form.shopping.value,
            contentText: form.description.value,
            contentUploads: uploadsNewPartner,
            socialMedia: socialMediaNewPartner,
            interviewName: form["interview-name"].value,
            interviewFirstName: form["interview-first-name"].value,
            interviewJob: form["interview-job"].value,
            interviewPhoto: contentNewPartner.interviewPhoto,
            interviewText: [interviewText, ...additionalTexts],
            subDomain: partnerSubDomain,
            url: urlPartner,
            isActive: false,
        };
        if (coordinatesOn) {
            newPartner.coordinates = [
                parseFloat(form.latitude.value.replace(",", ".")),
                parseFloat(form.longitude.value.replace(",", ".")),
            ];
        }
        dispatch(setPartner(newPartner));
        form["is-coordinates"].checked = false;
        setMainCategory(null);
        setSecondaryCategories([]);
        setCoordinatesOn(false);
        setCityNewPartner("");
        setLogoNewPartner("");
        setInterviewPhotoNewPartner("");
        setPartnerName("");
        setInterviewText("");
        setAdditionalTexts([]);
        setPartnerSubDomain("");
        dispatch(resetReducer());
        form.reset();
        setIsFormValid(false);
    };

    const customStylesCitySelector = {
        control: (provided) => ({
            ...provided,
            margin: "20px 0",
            padding: "5px",
            backgroundColor: "#e2b259",
            border: "2px solid #fff",
            boxShadow: "none",
            transition: "0.2s ease",
            "&:hover": {
                border: "2px solid #ccc",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
            color: state.isFocused ? "white" : "black",
            ":active": {
                backgroundColor: "white",
                color: "black",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "black",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: "white",
            transition: "0.2s ease",
            "&:hover": {
                color: "#ccc",
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#e2b259",
            border: "2px solid white",
            borderRadius: "4px",
            marginTop: "4px",
            zIndex: 9999,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "black",
            padding: "2px 8px",
            borderRadius: "4px",
        }),
    };

    return (
        <section className="new-partner">
            <h2>Création d'un nouveau partenaire</h2>
            <form id="form-new-partner" autoComplete="off" onSubmit={handleSubmit}>
                <section className="new-partner-setup">
                    <div className="info-new-partner">
                        <h3>Informations</h3>
                        <label htmlFor="name">Nom : </label>
                        <input
                            type="text"
                            id="name"
                            onChange={(e) => {
                                setPartnerName(e.target.value);
                                handleIsFormValid();
                            }}
                            value={partnerName}
                            placeholder="Nom du partenaire"
                            required
                        />
                        <label htmlFor="tel">Téléphone : </label>
                        <input
                            type="tel"
                            id="tel"
                            onChange={handleIsFormValid}
                            placeholder="Numéro de téléphone"
                        />
                        <label htmlFor="email">Email : </label>
                        <input
                            type="email"
                            id="email"
                            onChange={handleIsFormValid}
                            placeholder="Adresse e-mail"
                        />
                        <label htmlFor="website">Site web : </label>
                        <input
                            type="url"
                            id="website"
                            onChange={handleIsFormValid}
                            placeholder="Site web du partenaire"
                            pattern="https?://.+"
                        />
                        <label htmlFor="reservation">Réservation : </label>
                        <input
                            type="url"
                            id="reservation"
                            onChange={handleIsFormValid}
                            placeholder="Site web de réservation"
                            pattern="https?://.+"
                        />
                        <label htmlFor="meeting">Rendez-vous : </label>
                        <input
                            type="url"
                            id="meeting"
                            onChange={handleIsFormValid}
                            placeholder="Site web de rendez-vous"
                            pattern="https?://.+"
                        />
                        <label htmlFor="shopping">Boutique en ligne : </label>
                        <input
                            type="url"
                            id="shopping"
                            onChange={handleIsFormValid}
                            placeholder="Site web de la boutique en ligne"
                            pattern="https?://.+"
                        />
                        <label htmlFor="subDomain">Sous-domaine</label>
                        <input
                            type="text"
                            id="subDomain"
                            onChange={(e) => {
                                setPartnerSubDomain(e.target.value);
                                handleIsFormValid();
                            }}
                            value={partnerSubDomain}
                            placeholder="Sous-domaine du partenaire"
                            pattern="[a-z0-9\-]+"
                        />
                    </div>
                    <div className="city-interview-new-partner">
                        <div className="city-new-partner">
                            <h3>Ville attitrée</h3>
                            <section className="city-selector">
                                <Select
                                    options={optionsCity}
                                    onChange={handleCitySelected}
                                    styles={customStylesCitySelector}
                                    value={!isEmpty(cityNewPartner) ? cityNewPartner : null}
                                    placeholder="Sélectionnez une ville"
                                />
                            </section>
                            {!isEmpty(cityNewPartner) &&
                                dataCity
                                    .filter((city) => city._id === cityNewPartner.value)
                                    .map((city) => (
                                        <section key={city._id} className="location-info">
                                            <div className="without-coordinates">
                                                <h3>Adresse pour {city.name}</h3>
                                                <label htmlFor="street-number">Complément d'adresse : </label>
                                                <input
                                                    type="text"
                                                    id="street-number"
                                                    onChange={handleIsFormValid}
                                                />
                                                <label htmlFor="street-name">Numéro et rue : </label>
                                                <input
                                                    type="text"
                                                    id="street-name"
                                                    onChange={handleIsFormValid}
                                                    required
                                                />
                                                <label
                                                    id="btn-coordinates-activaction"
                                                    htmlFor="is-coordinates"
                                                >
                                                    {coordinatesOn
                                                        ? "Désactiver les coordonnées GPS"
                                                        : "Activer les coordonnées GPS"}
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    id="is-coordinates"
                                                    onChange={handleCoordinateChecked}
                                                    hidden
                                                />
                                            </div>
                                            <div className="with-coordinates">
                                                {coordinatesOn && (
                                                    <div className="coordinates-city">
                                                        <label htmlFor="latitude">Latitude :</label>
                                                        <input type="text" id="latitude" required/>
                                                        <label htmlFor="longitude">Longitude :</label>
                                                        <input type="text" id="longitude" required/>
                                                    </div>
                                                )}
                                            </div>
                                        </section>
                                    ))}
                        </div>
                        <div className="interview-new-partner">
                            <h3>Interview</h3>
                            <label htmlFor="interview-name">Nom :</label>
                            <input type="text" id="interview-name"/>

                            <label htmlFor="interview-first-name">Prénom :</label>
                            <input type="text" id="interview-first-name"/>

                            <label htmlFor="interview-job">Profession :</label>
                            <input type="text" id="interview-job"/>

                            <label htmlFor="interview-photo-content">
                                {isEmpty(contentNewPartner.interviewPhoto)
                                    ? "Ajouter une photo"
                                    : "Modifier la photo"}
                            </label>
                            <input
                                type="file"
                                id="interview-photo-content"
                                onChange={handleInterviewPhotoUpload}
                                hidden
                            />

                            <label htmlFor="interview-text" onClick={() => handleOpenModal(null)}>Texte :</label>
                            <textarea
                                id="interview-text"
                                value={interviewText}
                                onChange={(e) => setInterviewText(e.target.value)}
                                onClick={() => handleOpenModal(null)}
                                readOnly
                            />
                            {additionalTexts.map((text, index) => (
                                <textarea
                                    key={index}
                                    value={text}
                                    onChange={(e) => handleTextChange(index, e.target.value)}
                                    onClick={() => handleOpenModal(index)}
                                    placeholder={`Texte supplémentaire ${index + 1}`}
                                    readOnly
                                />
                            ))}
                            <button type="button" onClick={handleAddText}>Ajouter un texte</button>
                            {isModalOpen && (
                                <div className="modal-overlay">
                                    <div className="modal-content">
                                        <button className="modal-close" onClick={handleCloseModal}>X</button>
                                        <h3>Modifier le texte</h3>
                                        <textarea
                                            value={activeTextIndex === null ? interviewText : additionalTexts[activeTextIndex]}
                                            onChange={(e) => handleModalTextChange(e.target.value)}
                                            style={{ width: '100%', height: '500px' }}
                                        />
                                        <div className="modal-button-container">
                                            <button onClick={handleCloseModal}>Enregistrer</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="categories-new-partner">
                    <h3>Catégories</h3>
                        {flashMessage && <p className="flash-message">{flashMessage}</p>} {/* Flash message */}
                        <CategorySelector
                            onChange={(value) => onChangeCategorySelector(value, true)}
                            value={mainCategory}
                            placeholder={"Sélectionnez ou créez votre catégorie principale"}
                            isSingle={true}
                        />
                        <CategorySelector
                            onChange={(value) => onChangeCategorySelector(value, false)}
                            value={secondaryCategories}
                            placeholder={"Sélectionnez ou créez vos catégories secondaires"}
                            mainCategory={mainCategory}
                        />
                        <div className="description-new-partner">
                            <label htmlFor="description" id="label-description">
                                Description du partenaire :
                            </label>
                            <textarea id="description"/>
                        </div>
                    </div>
                    <div className="social-media-new-partner">
                        <h3>Réseaux sociaux</h3>
                        <label htmlFor="google">Google :</label>
                        <input
                            type="text"
                            id="google"
                            name="google"
                            pattern="https?://.+"
                            title="Exemple: https://www.google.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                        <label htmlFor="facebook">Page Facebook :</label>
                        <input
                            type="text"
                            id="facebook"
                            name="facebook"
                            pattern="https?://.+"
                            title="Exemple: https://www.facebook.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                        <label htmlFor="instagram">Instagram :</label>
                        <input
                            type="text"
                            id="instagram"
                            name="instagram"
                            pattern="https?://.+"
                            title="Exemple: https://www.instagram.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                        <label htmlFor="twitter">Twitter :</label>
                        <input
                            type="text"
                            id="twitter"
                            name="twitter"
                            pattern="https?://.+"
                            title="Exemple: https://www.twitter.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                        <label htmlFor="pinterest">Pinterest :</label>
                        <input
                            type="text"
                            id="pinterest"
                            name="pinterest"
                            pattern="https?://.+"
                            title="Exemple: https://www.pinterest.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                        <label htmlFor="tiktok">TikTok :</label>
                        <input
                            type="text"
                            id="tiktok"
                            name="tiktok"
                            pattern="https?://.+"
                            title="Exemple: https://www.tiktok.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                        <label htmlFor="youtube">Youtube :</label>
                        <input
                            type="text"
                            id="youtube"
                            name="youtube"
                            pattern="https?://.+"
                            title="Exemple: https://www.youtube.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                        <label htmlFor="linkedin">LinkedIn :</label>
                        <input
                            type="text"
                            id="linkedin"
                            name="linkedin"
                            pattern="https?://.+"
                            title="Exemple: https://www.linkedin.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                        <label htmlFor="tripadvisor">Tripadvisor (avis) :</label>
                        <input
                            type="text"
                            id="tripadvisor"
                            name="tripadvisor"
                            pattern="https?://.+"
                            title="Exemple: https://www.tripadvisor.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                        <label htmlFor="googleAvis">Google (avis) :</label>
                        <input
                            type="text"
                            id="googleAvis"
                            name="googleAvis"
                            pattern="https?://.+"
                            title="Exemple: https://www.googleAvis.com/..."
                            onChange={handleSocialMediaChanged}
                        />
                    </div>
                </section>
                <section className="new-partner-content">
                    <h3>Contenu</h3>
                    <div className="new-partner-content-container">
                        <label htmlFor="logo-content">
                            {isEmpty(contentNewPartner.logo)
                                ? "Ajouter un logo"
                                : "Modifier le logo"}
                        </label>
                        <input
                            type="file"
                            id="logo-content"
                            onChange={handleLogoUpload}
                            hidden
                            required
                        />
                        <label htmlFor="picture-content">Ajouter une photo</label>
                        <input
                            type="file"
                            id="picture-content"
                            onChange={handlePictureUpload}
                            hidden
                            multiple
                        />
                        <label htmlFor="video-content">Ajouter une vidéo</label>
                        <input
                            type="file"
                            id="video-content"
                            onChange={handleVideoUpload}
                            hidden
                        />
                    </div>
                </section>

                <div className="logo-interview">
                {!isEmpty(contentNewPartner.logo) && isEmpty(popupActive) && (
                    <section className="uploaded-logo">
                        <h3>Logo ajouté</h3>
                        <img
                            src={
                                "https://api.lesbonsplans.pro" +
                                "/upload/partner/image/" +
                                contentNewPartner.logo
                            }
                            alt="Logo ajouté"
                            width={200}
                        />
                    </section>
                )}
                {!isEmpty(contentNewPartner.interviewPhoto) && isEmpty(popupActive) && (
                    <section className="uploaded-logo">
                        <h3>Photo de rencontre ajoutée</h3>
                        <img
                            src={
                                "https://api.lesbonsplans.pro" +
                                "/upload/partner/image/" +
                                contentNewPartner.interviewPhoto
                            }
                            alt="InterviewPhoto ajouté"
                            width={200}
                        />
                    </section>
                )}
                </div>
                {!isEmpty(contentNewPartner.uploads) && isEmpty(popupActive) && (
                    <AdminSortingPartnerUploads/>
                )}
                <input
                    id={isFormValid ? "valid" : "invalid"}
                    type="submit"
                    value="Créer le partenaire"
                />
            </form>
        </section>
    );
};

export default AdminNewPartner;