import React, {useEffect, useState} from "react";
import HeaderAdmin from "../layouts/HeaderAdmin";
import NavbarAdmin from "../components/NavbarAdmin";
import CitySelector from "../components/CitySelector";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import {updateCity} from "../actions/city.action";
import {
    getContentCity,
    getContentCityById,
    setContentCity,
    updateContentCity,
} from "../actions/contentCity.action";
import AdminNewGuidedTour from "../layouts/AdminNewGuidedTour";
import AdminUpdateGuidedTour from "../layouts/AdminUpdateGuidedTour";
import {getGuidedTour} from "../actions/guidedTour.action";
import {resetAudioGuidedTour} from "../actions/contentGuidedTour.action";
import {resetPictureGuidedTour} from "../actions/pictureGuidedTour.action";
import AdminNewMagazine from "../layouts/AdminNewMagazine";
import {getMagazine} from "../actions/magazine.action";
import AdminUpdateMagazine from "../layouts/AdminUpdateMagazine";
import {resetReducer} from "../actions/partnerUploads.action";

const AdminContent = () => {
    const dataCity = useSelector((state) => state.cityReducer);
    const dataContentCity = useSelector((state) => state.contentCityReducer);
    const dataGuidedTour = useSelector((state) => state.guidedTourReducer);
    const dataMagazine = useSelector((state) => state.magazineReducer);
    const [valueCitySelector, setValueCitySelector] = useState({});
    const [citySelected, setCitySelected] = useState({});
    const [weatherLink, setWeatherLink] = useState("");
    const [infoCityLink, setInfoCityLink] = useState("");
    const [calendarLink, setCalendarLink] = useState("");
    const [liveCamLink, setLiveCamLink] = useState("");
    const [newsLink, setNewsLink] = useState("");
    const [magLink, setMagLink] = useState("");
    const [guidePrestigeLink, setGuidePrestigeLink] = useState("");
    const [interactCarteLink, setInteractCarteLink] = useState("");
    const [someNumberLink, setSomeNumberLink] = useState("");
    const [popupNewGuidedTour, setPopupNewGuidedTour] = useState(false);
    const [viewGuidedTour, setviewGuidedTour] = useState(false);
    const [viewMagazine, setviewMagazine] = useState(false);

    const [popupNewMagazine, setPopupNewMagazine] = useState(false);
    const [popupUpdateMagazine, setpopupUpdateMagazine] = useState(false);
    const [popupUpdateGuidedTour, setPopupUpdateGuidedTour] = useState(false);
    const [selectedElement, setselectedElement] = useState(null);
    const dispatch = useDispatch();

    const resetForm = () => {
        setValueCitySelector({});
        setCitySelected({});
        setWeatherLink("");
        setInfoCityLink("");
        setCalendarLink("");
        setLiveCamLink("");
        setNewsLink("");
        setMagLink("");
        setGuidePrestigeLink("");
        setInteractCarteLink("");
        setSomeNumberLink("");
    };


    useEffect(() => {
        if (
            isEmpty(citySelected.contentCity) &&
            !Array.isArray(dataContentCity) &&
            dataContentCity._id !== citySelected.contentCity &&
            citySelected._id !== undefined
        ) {
            dispatch(
                updateCity({
                    _id: citySelected._id,
                    contentCity: dataContentCity._id,
                })
            );
        }
    }, [dataContentCity]);

    useEffect(() => {
        if (
            !isEmpty(dataGuidedTour) &&
            !Array.isArray(dataGuidedTour) &&
            !Array.isArray(dataContentCity) &&
            !isEmpty(citySelected.contentCity)
        ) {
            let guidedTourID = dataGuidedTour._id;
            let updatedGuidedTour = [...dataContentCity.guidedTour, guidedTourID];
            const updatedContentCity = {
                _id: citySelected.contentCity,
                guidedTour: [...updatedGuidedTour],
            };
            dispatch(updateContentCity(updatedContentCity));
            dispatch(getGuidedTour());
        }
    }, [dataGuidedTour]);

    useEffect(() => {
        if (
            !isEmpty(dataMagazine) &&
            !Array.isArray(dataMagazine) &&
            !Array.isArray(dataContentCity) &&
            !isEmpty(citySelected.contentCity)
        ) {
            let magazineID = dataMagazine._id;
            let updatedMagazines = [...dataContentCity.magazines, magazineID];
            const updatedContentCity = {
                _id: citySelected.contentCity,
                magazines: [...updatedMagazines],
            };
            dispatch(updateContentCity(updatedContentCity));
            dispatch(getMagazine());
        }
    }, [dataMagazine]);

    useEffect(() => {
        dispatch(getContentCity());
        dispatch(getGuidedTour());
        dispatch(getMagazine());
        dataCity.forEach((city) => {
            if (city.name === valueCitySelector.value) {
                setCitySelected(city);
            }
        });
    }, [valueCitySelector]);

    useEffect(() => {
        if (Array.isArray(dataContentCity) && !isEmpty(citySelected.contentCity)) {
            dispatch(getContentCityById(citySelected.contentCity));
        }
        if (
            !isEmpty(citySelected.contentCity) &&
            !Array.isArray(dataContentCity) &&
            citySelected.contentCity === dataContentCity._id
        ) {
            setWeatherLink(dataContentCity.weatherLink);
            setInfoCityLink(dataContentCity.infoCityLink);
            setCalendarLink(dataContentCity.calendarLink);
            setLiveCamLink(dataContentCity.liveCamLink);
            setNewsLink(dataContentCity.cityNewsLink);
            setMagLink(dataContentCity.cityMagLink);
            setGuidePrestigeLink(dataContentCity.guidePrestigeLink);
            setInteractCarteLink(dataContentCity.interactCarteLink);
            setSomeNumberLink(dataContentCity.someNumberLink);
        }
    }, [citySelected, dataContentCity]);

    const handleChangeCitySelector = (value) => {
        resetForm();
        setValueCitySelector(value);
    };

    const onSubmitCreateContent = (e) => {
        e.preventDefault();
        let newContentCity = {
            weatherLink,
            infoCityLink,
            calendarLink,
            liveCamLink,
            cityNewsLink: newsLink,
            cityMagLink: magLink,
            guidePrestigeLink: guidePrestigeLink,
            interactCarteLink: interactCarteLink,
            someNumberLink: someNumberLink,
        };
        dispatch(setContentCity(newContentCity, citySelected._id));
        resetForm();
    };

    const onSubmitUpdateContent = (e) => {
        e.preventDefault();
        let updatedContentCity = {
            _id: citySelected.contentCity,
            weatherLink,
            infoCityLink,
            calendarLink,
            liveCamLink,
            cityNewsLink: newsLink,
            cityMagLink: magLink,
            guidePrestigeLink: guidePrestigeLink,
            interactCarteLink: interactCarteLink,
            someNumberLink: someNumberLink,
        };
        dispatch(updateContentCity(updatedContentCity));
        resetForm();
    };

    const handleGuidedTourClick = (tour) => {
        setselectedElement(tour);
        setPopupUpdateGuidedTour(true);
    };

    const handleMagazineClick = (mag) => {
        setselectedElement(mag);
        setpopupUpdateMagazine(true);
    };

    const closePopup = (action) => {
        if (action === "post") {
            setPopupNewGuidedTour(false);
        } else if (action === "update") {
            setPopupUpdateGuidedTour(false);
        } else if (action === "magazine") {
            setPopupNewMagazine(false);
        } else if (action === "updateMagazine") {
            setpopupUpdateMagazine(false);
        }
        dispatch(resetReducer());
        dispatch(resetAudioGuidedTour());
        dispatch(resetPictureGuidedTour());
    };

    return (
        <div className="admin-content-city-container">
            <HeaderAdmin/>
            <section className="admin-content-container">
                <NavbarAdmin/>
                <div className="admin-content">
                    <h2>Choisir la ville</h2>
                    <div className="selector-container">
                        <CitySelector
                            onChange={handleChangeCitySelector}
                            value={valueCitySelector}
                        />
                    </div>
                    {!isEmpty(citySelected) &&
                        (isEmpty(citySelected.contentCity) ? (
                            <form autoComplete="off" onSubmit={onSubmitCreateContent}>
                                <h3>Ajouter du contenu</h3>
                                <div className="weather-link-container link-container">
                                    <label htmlFor="weather-link">
                                        Lien vers la météo de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="weather-link"
                                        value={weatherLink}
                                        onChange={(e) => setWeatherLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="info-city-link-container link-container">
                                    <label htmlFor="info-city-link">
                                        Lien vers les informations pratique de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="info-city-link"
                                        value={infoCityLink}
                                        onChange={(e) => setInfoCityLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="calendar-city-link-container link-container">
                                    <label htmlFor="calendar-city-link">
                                        Lien vers l'agenda des festivités de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="calendar-city-link"
                                        value={calendarLink}
                                        onChange={(e) => setCalendarLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="live-cam-link-container link-container">
                                    <label htmlFor="live-cam-link">
                                        Lien vers les LiveCam de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="live-cam-link"
                                        value={liveCamLink}
                                        onChange={(e) => setLiveCamLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="news-city-link-container link-container">
                                    <label htmlFor="news-city-link">
                                        Lien vers les actualités de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="news-city-link"
                                        value={newsLink}
                                        onChange={(e) => setNewsLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="mag-city-link-container link-container">
                                    <label htmlFor="mag-city-link">
                                        Lien vers les magasines de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="mag-city-link"
                                        value={magLink}
                                        onChange={(e) => setMagLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="guide-prestige-link-container link-container">
                                    <label htmlFor="guide-prestige-link">
                                        Lien vers Les Bons Plans
                                    </label>
                                    <input
                                        type="text"
                                        id="guide-prestige-link"
                                        value={guidePrestigeLink}
                                        onChange={(e) => setGuidePrestigeLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="interact-carte-link-container link-container">
                                    <label htmlFor="interact-carte-link">
                                        Lien vers une carte interactive de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="interact-carte-link"
                                        value={interactCarteLink}
                                        onChange={(e) => setInteractCarteLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="some-number-link-container link-container">
                                    <label htmlFor="some-number-link">
                                        Lien vers quelques chiffres de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="some-number-link"
                                        value={someNumberLink}
                                        onChange={(e) => setSomeNumberLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <input type="submit" value="Ajouter le contenu"/>
                            </form>
                        ) : (
                            <form autoComplete="off" onSubmit={onSubmitUpdateContent}>
                                <h3>Mettre à jour le contenu</h3>
                                <div className="weather-link-container link-container">
                                    <label htmlFor="weather-link">
                                        Lien vers la météo de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="weather-link"
                                        value={weatherLink}
                                        onChange={(e) => setWeatherLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="info-city-link-container link-container">
                                    <label htmlFor="info-city-link">
                                        Lien vers les informations pratiques de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="info-city-link"
                                        value={infoCityLink}
                                        onChange={(e) => setInfoCityLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="calendar-city-link-container link-container">
                                    <label htmlFor="calendar-city-link">
                                        Lien vers l'agenda des festivités de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="calendar-city-link"
                                        value={calendarLink}
                                        onChange={(e) => setCalendarLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="live-cam-link-container link-container">
                                    <label htmlFor="live-cam-link">
                                        Lien vers les LiveCam de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="live-cam-link"
                                        value={liveCamLink}
                                        onChange={(e) => setLiveCamLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="news-city-link-container link-container">
                                    <label htmlFor="news-city-link">
                                        Lien vers les actualités de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="news-city-link"
                                        value={newsLink}
                                        onChange={(e) => setNewsLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="mag-city-link-container link-container">
                                    <label htmlFor="mag-city-link">
                                        Lien vers les magasines de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="mag-city-link"
                                        value={magLink}
                                        onChange={(e) => setMagLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="guide-prestige-link-container link-container">
                                    <label htmlFor="guide-prestige-link">
                                        Lien vers Les Bons Plans
                                    </label>
                                    <input
                                        type="text"
                                        id="guide-prestige-link"
                                        value={guidePrestigeLink}
                                        onChange={(e) => setGuidePrestigeLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="interact-carte-link-container link-container">
                                    <label htmlFor="interact-carte-link">
                                        Lien vers une carte interactive de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="interact-carte-link"
                                        value={interactCarteLink}
                                        onChange={(e) => setInteractCarteLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="some-number-link-container link-container">
                                    <label htmlFor="some-number-link">
                                        Lien vers quelques chiffres de la ville
                                    </label>
                                    <input
                                        type="text"
                                        id="some-number-link"
                                        value={someNumberLink}
                                        onChange={(e) => setSomeNumberLink(e.target.value)}
                                        pattern="https?://.+"
                                    />
                                </div>
                                <div className="label-guided-tour-magazine">
                                    <label
                                        className="label-btn"
                                        onClick={() => setPopupNewGuidedTour(true)}
                                    >
                                        Ajouter une visite guidée
                                    </label>
                                    <label
                                        className="label-btn"
                                        onClick={() => setviewGuidedTour(!viewGuidedTour)}
                                    >
                                        {viewGuidedTour
                                            ? "Cacher les visités guidées"
                                            : "Afficher les visités guidées"}
                                    </label>
                                    <label
                                        className="label-btn"
                                        onClick={() => setPopupNewMagazine(true)}
                                    >
                                        Ajouter un magazine
                                    </label>
                                    <label
                                        className="label-btn"
                                        onClick={() => setviewMagazine(!viewMagazine)}
                                    >
                                        {viewMagazine
                                            ? "Cacher les magazines"
                                            : "Afficher les magazines"}
                                    </label>
                                </div>
                                <input type="submit" value="Valider le contenu"/>
                            </form>
                        ))}
                    <section className="guided-tour-overview">
                        {!isEmpty(dataContentCity.guidedTour) &&
                            Array.isArray(dataGuidedTour) &&
                            viewGuidedTour &&
                            dataGuidedTour
                                .filter((tour) => dataContentCity.guidedTour.includes(tour._id))
                                .map((tour) => (
                                    <div
                                        key={tour._id}
                                        className="guided-tour"
                                        onClick={() => handleGuidedTourClick(tour)}
                                    >
                                        <img
                                            src={
                                                "https://api.lesbonsplans.pro/upload/upload/image/" +
                                                tour.picture
                                            }
                                            alt="image visite"
                                        />
                                        <p>{tour.name}</p>
                                    </div>
                                ))}
                    </section>
                    <section className="magazine-overview">
                        {!isEmpty(dataContentCity.magazines) &&
                            Array.isArray(dataMagazine) &&
                            viewMagazine &&
                            dataMagazine
                                .filter((mag) => dataContentCity.magazines.includes(mag._id))
                                .reverse()
                                .map((mag) => (
                                    <div
                                        key={mag._id}
                                        className="magazine"
                                        onClick={() => handleMagazineClick(mag)}
                                        style={{
                                            backgroundImage: `url(https://api.lesbonsplans.pro/upload/upload/image/${mag.pictures[0]?.id})`,
                                        }}
                                    >
                                        <div className="name-mag">
                                            <p>{mag.name}</p>
                                            <p>{mag.numberMag}</p>
                                        </div>
                                    </div>
                                ))}
                    </section>
                </div>
            </section>
            {popupNewGuidedTour && (
                <AdminNewGuidedTour resetPopup={() => closePopup("post")}/>
            )}
            {popupUpdateGuidedTour && selectedElement && (
                <AdminUpdateGuidedTour
                    contentCityId={citySelected.contentCity}
                    guidedTour={selectedElement}
                    closePopup={() => closePopup("update")}
                />
            )}
            {popupNewMagazine && (
                <AdminNewMagazine closePopup={() => closePopup("magazine")}/>
            )}
            {popupUpdateMagazine && selectedElement && selectedElement.pictures && (
                <AdminUpdateMagazine
                    contentCityId={citySelected.contentCity}
                    magazine={selectedElement}
                    closePopup={() => closePopup("updateMagazine")}
                />
            )}
        </div>
    );
};

export default AdminContent;