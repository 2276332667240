import React from "react";
import Header from "../layouts/Header";
import { useSelector } from "react-redux";

const Calendar = () => {
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);
  const currentCity = useSelector((state) => state.cityReducer);

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

  return (
      <div className="calendar-container" style={cityBackground}>
          <Header/>
          <h1>Agenda</h1>
          <div className="button-container">
              <button className="btn btn-link"
                      onClick={() => window.open(contentCurrentCity.calendarLink, '_blank')}>Si la page ne s'affiche pas
                  ou qu'elle s'affiche mal, cliquez ici.
              </button>
          </div>
          <section className="iframe-container">
              <iframe
                  title="calendar"
                  src={contentCurrentCity.calendarLink}
                  frameborder="0"
              ></iframe>
          </section>
      </div>
  );
};

export default Calendar;
